import React, { Component, createRef } from 'react';
import { bindActionCreators } from 'redux';
import { ImagePlus } from 'lucide-react';
import Pica from 'pica';
import ReCAPTCHA from 'react-google-recaptcha';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import config from '@/config';
import { getLanguage } from '@/services/GNRL';
import { NovovilleButton, Typography } from '@/components/novoville';
import { toast } from '@/components/ui/use-toast';
import { translate } from '../../services/TranslationService';
import { getReports } from '../reports/actions';
import {
  addPhoto,
  postReport,
  purgeReducer,
  setNewReportNavigation,
} from './actions';

const pica = new Pica();

const MAX_SIZE = 800;

class NRPhoto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      captchaResponse: null,
      language: getLanguage(),
      recaptchaKey: 0,
    };
    this.recaptchaRef = createRef();
    this.fileInputRef = createRef();
  }
  handleFileUpload = (file) => {
    if (!file) return;

    const reader = new FileReader();

    reader.onload = async ({ target }) => {
      try {
        const image = new Image();
        image.src = target.result;

        image.onload = () => {
          this.resizeImage(image, file.name);
        };
      } catch (error) {
        console.error('Error handling file upload:', error);
        toast({
          title: translate('OTHER.errorOcurred'),
          description: translate('Failed to process uploaded file'),
          variant: 'destructive',
        });
      }
    };

    reader.onerror = () => {
      toast({
        title: translate('OTHER.errorOcurred'),
        description: translate('Failed to read the file'),
        variant: 'destructive',
      });
    };

    reader.readAsDataURL(file);
  };

  resizeImage = async (image, name) => {
    try {
      const canvas = document.createElement('canvas');
      const isLandscape = image.width >= image.height;

      const [canvasWidth, canvasHeight] = isLandscape
        ? [
            Math.min(MAX_SIZE, image.width),
            Math.round(image.height * (canvas.width / image.width)),
          ]
        : [
            Math.round(image.width * (canvas.height / image.height)),
            Math.min(MAX_SIZE, image.height),
          ];

      canvas.width = canvasWidth;
      canvas.height = canvasHeight;

      const resizedCanvas = await pica.resize(image, canvas, { quality: 0 });
      const blob = await pica.toBlob(resizedCanvas, 'image/jpeg', 0.8);

      const photoFile = new File([blob], name, { type: 'image/jpeg' });
      this.props.addPhoto(blob, photoFile);
    } catch (err) {
      console.error(err);
      toast({
        title: translate('OTHER.errorOcurred'),
        description: err?.details?.user_description,
        variant: 'destructive',
      });
    }
  };

  handleCaptchaChange = (captchaResponse) => {
    this.setState({ captchaResponse });
  };

  handleCaptchaExpired = () => {
    this.setState({ captchaResponse: null });
  };

  handleSubmit = () => {
    const {
      postReport,
      setNewReportNavigation,
      purgeReducer,
      history,
      getReports,
    } = this.props;

    this.setState({ loading: true });

    const captchaValue = this.recaptchaRef.current.getValue();
    postReport(process.env.REACT_APP_NV_CAPTCHA_KEY, captchaValue)
      .then(() => {
        getReports();
      })
      .then(() => {
        setNewReportNavigation('SELECT_LOCATION');
        this.props.addPhoto(null, null);
        this.fileInputRef.current.value = '';
        purgeReducer();
        history.push('/home');
        toast({
          title: translate('OTHER.success'),
          description: translate('Report submitted successfully'),
          variant: 'success',
        });
      })
      .catch((err) => {
        console.error(err);
        toast({
          title: translate('OTHER.errorOcurred'),
          description: err?.details?.user_description,
          variant: 'destructive',
        });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { blobPhoto } = this.props;
    const { loading, captchaResponse, language, recaptchaKey } = this.state;

    const isScreenMobile =
      window.innerWidth < config.customMedia.recaptchaCompactThreshold;

    return (
      <div className="flex sm:p-[40px] sm:pt-[32px] sm:pb-0 p-0 sm:h-fit h-[calc(100vh-80px)]">
        <div className="flex flex-col bg-white w-full sm:p-[32px] p-[24px] sm:rounded-[16px] rounded-0 gap-[24px]">
          <div className="flex flex-col h-full lg:max-w-fit w-full">
            {/* Header */}
            <div className="flex justify-between items-center pb-[24px]">
              <Typography variant="bold_20" className="text-gray-800">
                {translate('Add photo')}
              </Typography>
              <Typography
                variant="regular_14"
                className="text-gray-500 lg:hidden block"
              >
                {`${translate('Step')} 4/4`}
              </Typography>
            </div>

            {/* Photo Upload Section */}
            <div className="flex w-full items-center justify-center bg-gray-50">
              <div className="flex flex-col items-center justify-center min-h-[170px] h-fit gap-[16px] p-[16px]">
                {blobPhoto ? (
                  <img
                    src={URL.createObjectURL(blobPhoto)}
                    alt="report_photo"
                    className="w-[170px] h-[170px] object-cover"
                  />
                ) : (
                  <ImagePlus className="w-[40px] h-[40px] text-gray-300" />
                )}
                <input
                  type="file"
                  id="picture"
                  style={{ display: 'none' }}
                  ref={this.fileInputRef}
                  onChange={(e) => this.handleFileUpload(e.target.files[0])}
                />
                <NovovilleButton
                  variant="secondary"
                  onClick={() => this.fileInputRef.current.click()}
                  className="w-full"
                >
                  {translate('Add photo')}
                </NovovilleButton>
              </div>
            </div>

            {/* Submit Section */}
            <div className="flex flex-col lg:max-w-fit w-full h-full justify-between">
              <ReCAPTCHA
                key={recaptchaKey}
                size={isScreenMobile ? 'compact' : 'normal'}
                sitekey={process.env.REACT_APP_NV_CAPTCHA_KEY}
                onChange={this.handleCaptchaChange}
                onExpired={this.handleCaptchaExpired}
                ref={this.recaptchaRef}
                hl={language === 'gr' ? 'el' : 'en'}
                className="flex justify-center my-10"
              />
              <NovovilleButton
                variant="primary"
                className="lg:w-[140px] py-[16px] w-full"
                loading={loading}
                disabled={!blobPhoto || !captchaResponse}
                onClick={this.handleSubmit}
              >
                {translate('SUBMIT')}
              </NovovilleButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  blobPhoto: state.newReportReducer.blobPhoto,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setNewReportNavigation,
      addPhoto,
      postReport,
      purgeReducer,
      getReports,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NRPhoto)
);
