import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { setOriginalUrlParameters } from '../../shared/actions';
import AddEmail from '../addEmail/AddEmail';
import AddPhone from '../addPhone/AddPhone';
import AuthoritySelection from '../authoritySelection/AuthoritySelection';
import Calendar from '../calendar/Calendar';
import CalendarEvent from '../calendar/CalendarEvent';
import Discussions from '../discussions/Discussions';
import FAQS from '../faqs/FAQS';
import Home from '../home/Home';
import Login from '../login/LoginContainer';
import MessageDetails from '../messages/MessageDetails';
import Messages from '../messages/Messages';
import MyCity from '../myCity/MyCity';
import NewReport from '../newReport/NewReport';
import Phonebook from '../phonebook/Phonebook';
import Privacy from '../privacy/Privacy';
import Profile from '../profile/Profile';
import Register from '../register/Register';
import ReportDetails from '../reports/ReportDetails';
import Reports from '../reports/Reports';
import TutorialPageContainer from '../startup/Tutorial';

class ProtectedRoutes extends Component {
  render() {
    let { token, authority, mobile, location, modulesEnabled } = this.props;
    let urlParams = location.search;
    let comp;
    if (urlParams) {
      comp = <Redirect key="0" to="/" />;
    }

    let routes = createRoutesBasedOnModules(
      modulesEnabled,
      token,
      authority,
      mobile
    );

    var allRoutes = [comp];

    allRoutes = allRoutes.concat(routes);

    return allRoutes;
  }
}

const createRouteWithRequirements = (
  component,
  url,
  requirements,
  injections,
  exact
) => {
  return (
    <Route
      exact={!!exact}
      key={url}
      path={url}
      render={() => {
        if (requirements.includes('token') && !injections.token) {
          return <Redirect to="/login" />;
        }

        if (
          requirements.includes('unauthenticated') &&
          injections.token &&
          injections.authority
        ) {
          return <Redirect to="/home" />;
        }

        if (requirements.includes('mobile') && !injections.mobile) {
          return <Redirect to={`/addPhone#from=${url}`} />;
        }

        if (requirements.includes('authority') && !injections.authority) {
          console.log('Cae from URL:', url);

          return (
            <Redirect
              to={{
                pathname: '/authoritySelection',
                state: { from: url },
              }}
            />
          );
        }

        return component;
      }}
    />
  );
};

var mapStateToProps = (state, ownProps) => {
  return {
    token: state.authReducer.auth_token,
    mobile: state.profileReducer.profile.mobile,
    authority: state.profileReducer.profile.authority,
    modulesEnabled: state.otherReducer.modules,
    location: ownProps.location,
  };
};

var mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setOriginalUrlParameters,
    },
    dispatch
  );

const ConnectedProtectedRoutes = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProtectedRoutes);
export default ConnectedProtectedRoutes;

//TODO: Find out why moving Switch here doesnt work.
//TODO: MOVE THIS

function createRoutesBasedOnModules(modulesEnabled, token, authority, mobile) {
  //TODO: create generator of keys so that every time its called it creates a unique one

  let entries = [];
  modulesEnabled = modulesEnabled.map((item) => item.name);
  let localStorageIsAvailable;
  try {
    localStorageIsAvailable = true;
  } catch (ex) {
    localStorageIsAvailable = false;
  }
  if (localStorageIsAvailable) {
    let seenTutorial = localStorage?.getItem('seenTutorial');
    let hasEverRegistered = localStorage?.getItem('hasEverRegistered');

    if (!seenTutorial) {
      localStorage.setItem('seenTutorial', true);
      entries.push(
        createRouteWithRequirements(
          <TutorialPageContainer key="0" />,
          '/',
          [],
          { token, authority },
          true
        )
      );
    } else {
      if (hasEverRegistered) {
        entries.push(
          createRouteWithRequirements(
            <Login key="1" />,
            '/',
            ['unauthenticated'],
            { token, authority },
            true
          )
        );
      } else {
        entries.push(
          createRouteWithRequirements(
            <Register key="1" />,
            '/',
            ['unauthenticated'],
            { token, authority },
            true
          )
        );
      }
    }
  } else {
    entries.push(
      createRouteWithRequirements(
        <Register key="1" />,
        '/register',
        ['unauthenticated'],
        { token, authority },
        true
      )
    );
  }

  let defaultEntries = [
    createRouteWithRequirements(
      <Login key="2" />,
      '/login',
      ['unauthenticated'],
      { token, authority }
    ),
    createRouteWithRequirements(
      <Register key="3" />,
      '/register',
      ['unauthenticated'],
      { token, authority }
    ),
    createRouteWithRequirements(
      <AuthoritySelection key="4" />,
      '/authoritySelection',
      ['token'],
      { token, authority }
    ),
    createRouteWithRequirements(
      <Home key="5" />,
      '/home',
      ['token', 'authority'],
      { token, authority }
    ),
    createRouteWithRequirements(
      <AddPhone key="13" />,
      '/addPhone',
      ['token', 'authority'],
      { token, authority }
    ),
    createRouteWithRequirements(
      <AddEmail key="13email" />,
      '/verifyEmail',
      ['token', 'authority'],
      { token, authority }
    ),
    createRouteWithRequirements(
      <Profile key="15" />,
      '/profile',
      ['token', 'authority'],
      { token, authority }
    ),
    createRouteWithRequirements(
      <Privacy key="16" />,
      '/privacy',
      ['token', 'authority'],
      { token, authority }
    ),
  ];
  entries = entries.concat(defaultEntries);

  if (modulesEnabled.indexOf('messages') !== -1) {
    entries.push(
      createRouteWithRequirements(
        <Messages key="6" />,
        '/messages',
        ['token', 'authority'],
        { token, authority },
        true
      )
    );
    entries.push(
      createRouteWithRequirements(
        <MessageDetails key="7" />,
        '/messages/:message_id',
        ['token', 'authority'],
        { token, authority }
      )
    );
  }

  if (modulesEnabled.indexOf('mycity') !== -1) {
    entries.push(
      createRouteWithRequirements(
        <MyCity key="12" />,
        '/myCity',
        ['token', 'authority'],
        { token, authority }
      )
    );
  }

  if (modulesEnabled.indexOf('reports') !== -1) {
    entries.push(
      createRouteWithRequirements(
        <NewReport key="14" />,
        '/newReport',
        ['token', 'authority', 'mobile'],
        { token, authority, mobile }
      )
    );
    entries.push(
      createRouteWithRequirements(
        <Reports key="8" />,
        '/reports',
        ['token', 'authority'],
        { token, authority },
        true
      )
    );
    entries.push(
      createRouteWithRequirements(
        <ReportDetails key="9" />,
        '/reports/:report_id',
        ['token', 'authority'],
        { token, authority }
      )
    );
  }

  if (modulesEnabled.indexOf('phones') !== -1) {
    entries.push(
      createRouteWithRequirements(
        <Phonebook key="11" />,
        '/phonebook',
        ['token', 'authority'],
        { token, authority }
      )
    );
  }
  if (modulesEnabled.indexOf('faqs') !== -1) {
    entries.push(
      createRouteWithRequirements(
        <FAQS key="10" />,
        '/faqs',
        ['token', 'authority'],
        { token, authority }
      )
    );
  }

  if (modulesEnabled.indexOf('calendar') !== -1) {
    entries.push(
      createRouteWithRequirements(
        <Calendar key="calendar" />,
        '/calendar',
        ['token', 'authority'],
        { token, authority },
        true
      )
    );

    entries.push(
      createRouteWithRequirements(
        <CalendarEvent key="calendar-event" />,
        '/calendar/:eventId',
        ['token', 'authority'],
        {
          token,
          authority,
        }
      )
    );
  }

  if (modulesEnabled.indexOf('discussions') !== -1) {
    entries.push(
      createRouteWithRequirements(
        <Discussions key="discussions" />,
        '/discussions',
        [('token', 'authority')],
        { token, authority }
      )
    );
  }

  return entries;
}
