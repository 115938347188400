import discussionSvg from '@/resources/discussions.svg';
import React from 'react';
import { translate } from '@/services/TranslationService';
import { Typography } from '@/components/novoville';

const DiscussionsEmptyList = () => {
  return (
    <div className="flex flex-1 flex-col item-center justify-center px-6">
      <img
        src={discussionSvg}
        alt="No active Discussion"
        className="max-w-32 w-full h-auto mx-auto"
      />
      <Typography variant="semibold_18" className="text-center mt-6">
        {translate('CONSULTATION.emptyListTitle')}
      </Typography>
      <Typography variant="regular_16" className="text-center mt-3">
        {translate('CONSULTATION.emptyListSubtitle')}
      </Typography>
    </div>
  );
};

export default DiscussionsEmptyList;
