import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

/**
 * Format phone number
 * @param {Object} param0
 * @param {string} param0.phoneNumber
 * @param {boolean} param0.needsPlusSign
 * @param {boolean} param0.removeCountryCode
 * @returns {string}
 * @description
 * Format phone number
 * @example
 * formatPhoneNumber({
 *  phoneNumber: '+1234567890',
 * needsPlusSign: true,
 * removeCountryCode: true,
 * }); // 1234567890
 *
 * formatPhoneNumber({
 * phoneNumber: '1234567890',
 * needsPlusSign: true,
 * removeCountryCode: false,
 * }); // +1234567890
 * @todo
 * Make this function more robust and sync with the mobile app implementation
 */

export const formatPhoneNumber = ({
  phoneNumber,
  needsPlusSign = false,
  removeCountryCode = false,
}) => {
  const cleanPhoneNumber = phoneNumber.replace(/[^+\d]/g, '');

  const hasPlusSign = cleanPhoneNumber.startsWith('+');

  if (needsPlusSign && !hasPlusSign) {
    return `+${cleanPhoneNumber}`;
  }

  if (removeCountryCode && hasPlusSign) {
    return cleanPhoneNumber.slice(-10);
  }

  return cleanPhoneNumber;
};
