import React, { useState } from 'react';
import { List } from 'lucide-react';
import { useDispatch } from 'react-redux';
import { NovovilleButton, Typography } from '@/components/novoville';
import { Badge } from '@/components/ui/badge';
import { Separator } from '@/components/ui/separator';
import { toast } from '@/components/ui/use-toast';
import { translate } from '../../services/TranslationService';
import { goBackRouter } from '../../shared/actions';
import { withTranslation } from '../../shared/components/withTranslation';
import PollQuestion from './PollsQuestions/PollQuestion';
import { postPoll } from './actions';
import moment from 'moment';

const messageExpiredOrAnswered = (message) => {
  if (message.pollObject.answered || message.pollObject.expired) {
    return true;
  }
};

const getPollButtonText = (message) => {
  if (message.pollObject.answered) {
    return translate('PollAnswered');
  } else if (message.pollObject.expired) {
    return translate('PollExpired');
  } else {
    return translate('Submit');
  }
};

const formatDefaultValue = (questions) => {
  return questions.reduce((acc, question) => {
    const { id } = question;
    return {
      ...acc,
      [id]: question,
    };
  }, {});
};

const PollMessageItem = (props) => {
  const { message, className } = props;
  const [loading, setLoading] = useState(false);

  const defaultValues = formatDefaultValue(message.questions);

  const [answeredValues, setAnsweredValues] = useState(defaultValues);

  const allQuestionsAnswered = Object.values(answeredValues).every(
    (question) => question.answer
  );

  const dispatch = useDispatch();

  const messageId = message.id;
  const messageTime = message?.created_at;

  const isPollActive =
    !message.pollObject.expired && !message.pollObject.answered;

  const handleAnswerChange = (questionId) => (value) => {
    setAnsweredValues((prev) => ({
      ...prev,
      [questionId]: {
        ...prev[questionId],
        answer: value,
      },
    }));
  };

  const handleSubmit = () => {
    const formattedSelectedAnswers = Object.values(answeredValues).reduce(
      (acc, question) => {
        return {
          ...acc,
          [question.id]: question.answer,
        };
      },
      {}
    );

    setLoading(true);
    dispatch(
      postPoll({
        message_id: messageId,
        answer: formattedSelectedAnswers,
        authority: message.authority_name,
      })
    )
      .then(() => {
        setLoading(false);
        toast({
          title: translate('OTHER.success'),
          description: translate('POLL.succesfullySent'),
          variant: 'success',
        });
        dispatch(goBackRouter());
      })
      .catch((err) => {
        setLoading(false);
        const errorMessage =
          err.details && err.details.user_description
            ? err.details.user_description
            : translate('OTHER.genericError');
        toast({
          title: translate('OTHER.errorOcurred'),
          description: errorMessage,
          variant: 'destructive',
        });
      });
  };

  const badgeText = isPollActive
    ? translate('Active')
    : translate('BottomSheet_closed');

  return (
    <div
      className={`flex sm:p-[20px] sm:h-[calc(100vh-120px)] h-[calc(100vh-80px)] sm:pb-0 p-0 ${className}`}
    >
      <div className="flex flex-col sm:p-[32px] p-[24px] bg-white w-full sm:rounded-md rounded-0 sm:overflow-auto overflow-y-scroll">
        {message.photo && (
          <div className="flex w-full max-h-80 my-6">
            <img
              src={message.photo}
              alt={message.title}
              className="object-contain w-full h-full rounded-lg"
            />
          </div>
        )}
        <div className="mb-2 flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <List className="h-5 w-5 text-peach" />
            <Typography variant="semibold_16" className="text-gray-800">
              {translate(message.app_type)}
            </Typography>
          </div>
          <div className="flex flex-row items-center space-x-2">
            <Typography variant="regular_14" className="text-gray-500">
              {moment(messageTime).format('DD/MM/YYYY')}
            </Typography>
            <Badge
              variant="outline"
              className={`bg-badgeSuccess text-black py-[4px] px-[12px] ${
                isPollActive ? 'bg-badgeSuccess' : 'bg-gray-300'
              }`}
            >
              <Typography
                variant="regular_12"
                className="text-gray-800 text-[10px]"
              >
                {badgeText}
              </Typography>
            </Badge>
          </div>
        </div>
        <Separator className="my-[8px] bg-gray-300" />
        <Typography
          variant="bold_20"
          className="text-gray-800 break-words pb-[6px]"
        >
          {message.title}
        </Typography>
        <div className="questions">
          {Object.values(answeredValues).map((question, index) => {
            if (!question) return null;
            return (
              <PollQuestion
                key={question.id}
                question={question}
                handleChange={handleAnswerChange(question.id)}
                isActive={isPollActive}
              />
            );
          })}
        </div>
        <div className="text-center self-end sm:w-fit w-full">
          <NovovilleButton
            variant="primary"
            className=" mt-[24px] sm:w-fit w-full"
            loading={loading}
            disabled={
              messageExpiredOrAnswered(message) || !allQuestionsAnswered
            }
            onClick={handleSubmit}
          >
            {getPollButtonText(message)}
          </NovovilleButton>
        </div>
      </div>
    </div>
  );
};

export default withTranslation(PollMessageItem);
