import WEBAPI from '../../services/WEBAPI';
import * as ATypes from '../../shared/actionTypes';

export const checkVerification = (mobileOrEmail) => {
  return (dispatch, getState) => {
    return WEBAPI.checkIfVerified(mobileOrEmail).then((res) => {
      if (mobileOrEmail?.includes('@')) {
        dispatch({
          type: ATypes.CHECK_EMAIL_VERIFY_RES,
          payload: res.result[`${mobileOrEmail}`],
        });
      } else {
        dispatch({
          type: ATypes.CHECK_MOBILE_VERIFY_RES,
          payload: res.result[`${mobileOrEmail}`],
        });
      }

      return res;
    });
  };
};

export const checkVerificationAll = () => {
  return async (dispatch, getState) => {
    const { hasVerifiedEmail, hasVerifiedPhoneNumber, profile } =
      getState().profileReducer;

    const { mobile, email } = profile;

    if (hasVerifiedEmail && hasVerifiedPhoneNumber) {
      return Promise.resolve();
    }

    dispatch({
      type: ATypes.GET_VERIFIED_ALL_REQ,
    });

    try {
      const res = await WEBAPI.checkIfVerifiedAll();

      const verifiedEmail = res.result?.[email];
      const verifiedMobile = res.result?.[mobile];

      const payload = {
        hasVerifiedEmail: !!verifiedEmail,
        hasVerifiedPhoneNumber: !!verifiedMobile, // "!!" in case the value is undefined
      };

      dispatch({
        type: ATypes.GET_VERIFIED_ALL_RES,
        payload,
      });

      return res;
    } catch (error) {
      // TODO: Make it with logger if needed
      console.error('Verification check failed', error);
      dispatch({
        type: ATypes.GET_VERIFIED_ALL_FAIL,
        error,
      });
      return Promise.reject(error);
    }
  };
};
