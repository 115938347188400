import React from 'react';
import { Typography } from '@material-ui/core';
import { Form } from '@/components/ui/form';
import PollAnswers from './PollAnswers';

const PollQuestion = ({ question, handleChange, isActive }) => {
  const { question: questionTitle } = question;
  return (
    <div className="my-4 pb-4 border-b last:border-b-0 border-gray-300">
      <Form>
        <Typography variant="bold_16" className="text-gray-700 py-[16px]">
          {questionTitle}
        </Typography>
        <PollAnswers
          question={question}
          handleChange={handleChange}
          isActive={isActive}
        />
      </Form>
    </div>
  );
};

export default PollQuestion;
