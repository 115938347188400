import React, { memo, useEffect, useState } from 'react';
import { Typography } from '@/components/novoville';
import { translate, translatePOIType } from '../../services/TranslationService';
import MyCityListItem from './MyCityListItem';

const FiltersComponent = ({
  isMobile,
  pois = [],
  reports,
  activeFilters = [],
  filtersChanged,
  clearFilters,
}) => {
  const [availableFilters, setAvailableFilters] = useState([
    { name: 'REPORTS', translatedName: translate('Reports'), value: 0 },
    {
      name: 'PUBLIC_BUILDING',
      translatedName: translatePOIType('PUBLIC_BUILDING'),
      value: 0,
    },
    { name: 'MUSEUM', translatedName: translatePOIType('MUSEUM'), value: 0 },
    {
      name: 'LANDMARK',
      translatedName: translatePOIType('LANDMARK'),
      value: 0,
    },
    {
      name: 'PHARMACY',
      translatedName: translatePOIType('PHARMACY'),
      value: 0,
    },
    {
      name: 'GAS_STATION',
      translatedName: translatePOIType('GAS_STATION'),
      value: 0,
    },
    { name: 'POLICE', translatedName: translatePOIType('POLICE'), value: 0 },
    {
      name: 'ADOPT_A_TREE',
      translatedName: translatePOIType('ADOPT_A_TREE'),
      value: 0,
    },
  ]);

  useEffect(() => {
    if (!pois || !reports) {
      return;
    }

    const countMap = pois.reduce((acc, poi) => {
      acc[poi.type] = (acc[poi.type] || 0) + 1;
      return acc;
    }, {});

    countMap['REPORTS'] = reports.length || 0;

    setAvailableFilters((filters) =>
      filters.map((filter) => ({
        ...filter,
        value: countMap[filter.name] || 0,
      }))
    );
  }, [pois, reports]);

  return (
    <div className={`flex flex-col ${isMobile ? 'p-6' : ''}`}>
      {!isMobile && (
        <div className="flex justify-start items-center gap-4">
          <Typography variant="bold_20">{translate('Filters')}</Typography>
          {activeFilters.length > 0 && (
            <Typography
              variant="semibold_16"
              onClick={clearFilters}
              className="cursor-pointer text-gray-500 underline hover:text-gray-600"
            >
              {translate('ClearFilters')}
            </Typography>
          )}
        </div>
      )}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-10">
        {availableFilters.map((item, index) => {
          const isChecked = activeFilters.some(
            (i) => i.name === item.name && item.value
          );

          return (
            <MyCityListItem
              key={item.name + index}
              text={`${item.translatedName} (${item.value})`}
              isChecked={isChecked}
              disabled={item.value === 0}
              onClick={() => filtersChanged(item.name)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default memo(FiltersComponent);
