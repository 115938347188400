import React, { Component } from 'react';
import { Calendar, List, Megaphone, MessageCircleWarning } from 'lucide-react';
import moment from 'moment';
import { withRouter } from 'react-router';
import { translate } from '@/services/TranslationService';
import { Typography } from '@/components/novoville';
import { Separator } from '@/components/ui/separator';
import InformationScreen from '../../shared/components/InformationScreen';

const iconMap = {
  EVENT: <Calendar className="h-5 w-5 text-purpleLink" />,
  ANNOUNCEMENT: <MessageCircleWarning className="h-5 w-5 text-blueLink" />,
  POLL: <List className="h-5 w-5 text-peach" />,
  ALL: <Megaphone className="h-5 w-5 text-blueLink" />,
};

class MessageItem extends Component {
  constructor(props) {
    super();
    this.props = props;
    this.state = {
      icon: '',
      messageTime: '',
      showImageClass: '',
    };
  }

  componentDidMount() {
    const { message, showImage = true } = this.props;
    let icon = iconMap[message.app_type];
    let messageTime = message?.created_at?.substr(0, 10);
    this.setState({ icon: icon, messageTime: messageTime });
    if (showImage && message.photo !== '') {
      this.setState({ showImageClass: 'with-image' });
    }
  }

  navigate = () => {
    this.props.history.push(`/messages/${this.props.message.id}`);
  };

  render() {
    const {
      message,
      display,
      showImage = true,
      className,
      imageWidth,
    } = this.props;
    return (
      <>
        {message ? (
          <Message
            message={message}
            showImageClass={this.state.showImageClass}
            display={display}
            translate={translate}
            onClick={this.navigate}
            showImage={showImage}
            icon={this.state.icon}
            messageTime={this.state.messageTime}
            className={className}
            imageWidth={imageWidth}
          />
        ) : (
          <div
            style={{
              flex: 1,
              flexGrow: 1,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <InformationScreen />
          </div>
        )}
      </>
    );
  }
}

const Message = (props) => {
  const {
    message,
    icon,
    messageTime,
    showImage,
    onClick,
    imageWidth,
    translate,
    className,
  } = props;

  return (
    <div className="flex space-y-[16px] p-0 w-full">
      <div
        key={message.id}
        className={`flex flex-col lg:flex-row items-start gap-4 bg-white p-4 transition-shadow cursor-pointer w-full ${className}`}
        onClick={onClick}
      >
        {showImage && (
          <div className="w-full lg:max-w-[300px] min-h-[90px]">
            <img
              src={
                message.photo
                  ? message.photo
                  : '/images2/other/Default_image_messages.png'
              }
              alt={message.title}
              className={`w-full h-full rounded-lg object-cover ${imageWidth}`}
            />
          </div>
        )}
        <div className="w-full">
          <div className="mb-2 flex items-center justify-between">
            <div className="flex items-center space-x-2">
              {icon}
              <Typography variant="semibold_16" className="text-gray-800">
                {translate(message.app_type)}
              </Typography>
            </div>
            <Typography variant="regular_14" className="text-gray-500">
              {messageTime}
            </Typography>
          </div>
          <Separator className="my-[8px] bg-gray-300" />
          <Typography
            variant="bold_16"
            className="text-gray-800 break-words pb-[6px]"
          >
            {message.title}
          </Typography>
          <Typography variant="regular_16" className="text-gray-700">
            {message.app_type === 'POLL'
              ? `${translate('POLLS.pollIsActiveUntil')} ${moment(message.pollObject.expires_at).format('DD/MM/YYYY')}.`
              : message.body}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export const MessageImage = ({ photoUrl }) => {
  if (!photoUrl || photoUrl === '') {
    photoUrl = '/images2/other/Default_image_messages.png';
  }

  return <img className="card-image-thumb" src={photoUrl} alt="message" />;
};

export default withRouter(MessageItem);
