import { discussionsReducer } from '@/features/discussions/reducer';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import _ from 'lodash';
import { composeWithDevTools } from 'redux-devtools-extension';
import logger from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import { permissionsReducer, settingsReducer } from '../../src/shared/reducers';
import { addPhoneReducer } from '../features/addPhone/reducers';
import { calendarReducer } from '../features/calendar/reducer';
import { faqsReducer } from '../features/faqs/reducers';
import { homeReducer } from '../features/home/reducers';
import { messagesReducer } from '../features/messages/reducers';
import { myCityReducer } from '../features/myCity/reducers';
import { newReportReducer } from '../features/newReport/reducers';
import { phonesReducer } from '../features/phonebook/reducers';
import { profileReducer } from '../features/profile/reducers';
import { authReducer } from '../features/register/reducers';
import { reportsReducer } from '../features/reports/reducers';
import { sideMenuReducer } from '../features/sideMenu/reducers';
import * as AnalyticsActions from '../services/analyticsActions';
// REDUCERS
import {
  countriesReducer,
  otherReducer,
  translationsReducer,
} from '../shared/reducers';

export const history = createBrowserHistory();

const rootReducer = combineReducers({
  countriesReducer,
  translationsReducer,
  authReducer,
  profileReducer,
  messagesReducer,
  reportsReducer,
  faqsReducer,
  phonesReducer,
  sideMenuReducer,
  myCityReducer,
  addPhoneReducer,
  newReportReducer,
  homeReducer,
  otherReducer,
  calendarReducer,
  settingsReducer,
  permissionsReducer,
  discussionsReducer,
  router: connectRouter(history),
});

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'otherReducer',
    'newReportReducer',
    'addPhoneReducer',
    // 'homeReducer',
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

let middleware = [thunk, routerMiddleware(history)]; // Ensure router middleware is correctly applied

if (process.env.WITH_REDUX_LOGGER === 'true') {
  middleware.push(logger);
}

const storeEnhancer = composeWithDevTools(applyMiddleware(...middleware));

const store = createStore(persistedReducer, storeEnhancer);

export const persistor = persistStore(store);

// SELECTORS and other properties remain unchanged

// SELECTORS
export const getAuthToken = () => {
  return store.getState().authReducer.auth_token;
};

export const getSelectedCountry = () => {
  return store.getState().countriesReducer.selected_country;
};

export const getSelectedAuthFromProfile = () => {
  return _.get(store.getState(), 'profileReducer.profile.authority');
};

export const getSelectedCountryFromProfile = () => {
  return _.get(store.getState(), 'profileReducer.profile.country');
};

export const getUserEmail = () => {
  return store.getState()?.profileReducer?.profile?.email;
};

export const getCameFrom = () => {
  return store.getState()?.homeReducer?.cameFrom;
};

export const dispatch = store.dispatch;

window.store = store;
window.getAuthToken = () => {
  return store.getState().authReducer.auth_token;
};
window.getSelectedCountry = () => {
  return store.getState().countriesReducer.selected_country;
};
window.getSelectedAuthFromProfile = () => {
  return _.get(store.getState(), 'profileReducer.profile.authority');
};
window.getSelectedCountryFromProfile = () => {
  return _.get(store.getState(), 'profileReducer.profile.country');
};
window.getUserEmail = () => {
  return store.getState()?.profileReducer?.profile?.email;
};

window.logout = () => {
  const isAthens =
    _.get(store.getState(), 'profileReducer.profile.authority') === 'athens';
  persistor.purge().then(() => {
    AnalyticsActions.logoutEvent();
    if (isAthens) {
      let currentUrl = window.location.origin;
      window.location.replace(`${currentUrl}?displayMessageAuthority=athens`);
    } else {
      persistor
        .flush()
        .then(() => {
          // boundInitialProcess()
        })
        .catch((e) => console.log(e));
      // window.location.reload()
    }
  });
  console.log('logout');
};
window.persistor = persistStore(store);

export default store;
