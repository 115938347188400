import React, { useEffect, useState } from 'react';
import { ArrowLeft } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { translate } from '@/services/TranslationService';
import store from '@/services/store';
import { toast } from '@/components/ui/use-toast';
import { withNOVOHeader } from '../sideMenu/HeaderHOC';
import DiscussionListItem from './DiscussionListItem';
import DiscussionsEmptyList from './DiscussionsEmptyList';
import DiscussionsLoading from './DiscussionsLoading';
import { getDiscussions } from './actions';

const createWebChatUrl = (discussionName) => {
  // const env = process.env.NODE_ENV;
  const baseUrl = process.env.REACT_APP_BASE_URL_WEBCHAT;
  const token = store.getState().authReducer.auth_token;
  const target = 'consultations';

  return `${baseUrl}?name=${discussionName}&Token=${token}&target=${target}&medium=app`;
};

const Discussions = (props) => {
  const { setHeaderTitle } = props;

  const [activeDiscussion, setActiveDiscussion] = useState(undefined);

  const dispatch = useDispatch();

  const { loading, error, discussions } = useSelector(
    (state) => state.discussionsReducer
  );

  useEffect(() => {
    setHeaderTitle(translate('sideMenu.Consultations'));
    dispatch(getDiscussions());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      // TODO: Add translation
      // TODO: Add error handling
      toast({
        title: 'Error',
        description: translate('Failed to fetch discussions'),
        variant: 'destructive',
      });
    }
  }, [error]);

  return (
    <div className="flex flex-1 flex-col relative">
      {loading && <DiscussionsLoading />}
      {discussions.length === 0 && !loading && <DiscussionsEmptyList />}

      {!activeDiscussion && discussions.length > 0 && (
        <div className="flex flex-col sm:m-10 m-0 sm:max-h-[calc(100vh-10rem)] py-2 px-8 overflow-y-auto bg-white">
          {discussions.map((discussion) => (
            <div
              key={discussion.name}
              className="flex border-b-2 last:border-b-0 border-gray-100"
            >
              <DiscussionListItem
                discussion={discussion}
                setActiveDiscussion={setActiveDiscussion}
              />
            </div>
          ))}
        </div>
      )}
      {activeDiscussion && (
        <div className="flex flex-col flex-1 py-4 px-6 bg-white ">
          <div className="flex">
            <ArrowLeft
              size={24}
              className="cursor-pointer"
              onClick={() => setActiveDiscussion(undefined)}
            />
          </div>
          <div className="flex-1 flex bg-white px-3 py-2 round-xl m-4">
            <iframe
              src={createWebChatUrl(activeDiscussion)}
              title="Webchat"
              className="flex-1"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default withNOVOHeader(Discussions);
