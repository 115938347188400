import React from 'react';
import FreeText from './FreeText';
import MultipleAnswers from './MultipleAnswers';
import OneAnswer from './OneAnswer';
import { ANSWERS_TYPES } from './answers';

const PollAnswers = ({ question, handleChange, isActive }) => {
  const { answer_type } = question;

  switch (answer_type) {
    case ANSWERS_TYPES.SINGLE:
      return (
        <OneAnswer
          question={question}
          handleChange={handleChange}
          isActive={isActive}
        />
      );
    case ANSWERS_TYPES.MULTIPLE:
      return (
        <MultipleAnswers
          question={question}
          handleChange={handleChange}
          isActive={isActive}
        />
      );
    case ANSWERS_TYPES.TEXT:
      return (
        <FreeText
          question={question}
          handleChange={handleChange}
          isActive={isActive}
        />
      );
    default:
      return null; // TODO: Add an translation for this case
  }
};

export default PollAnswers;
