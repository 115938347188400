import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { goTo } from '@/shared/actions';
import { translate } from '@/services/TranslationService';
import { NovovilleButton, Typography } from '@/components/novoville';

const CalendarEventError = () => {
  const dispatch = useDispatch();

  const returnToCalendar = () => {
    console.log();
    dispatch(goTo('calendar'));
  };

  return (
    <div className="flex flex-col flex-1 justify-center items-center container max-w-96 space-y-12">
      <Typography variant="bold_24" className="text-center">
        {translate('defaultErrorTitle')}
      </Typography>
      <Typography variant="regular_16" className="text-center">
        {translate('calendar.noCalendarEventSubtitle')}
      </Typography>
      <NovovilleButton
        variant="primary"
        text={translate('calendar.noCalendarEventBtn')}
        onClick={returnToCalendar}
      />
    </div>
  );
};

export default memo(CalendarEventError);
