import React from 'react';
import { translate } from '@/services/TranslationService';

const FreeText = ({ question, handleChange, isActive }) => {
  const { id: questionId, max_size, answer } = question;

  return (
    <>
      <div className="relative max-w-[530px] w-full my-1">
        <textarea
          id={questionId}
          type="text"
          name={questionId}
          value={answer}
          onChange={(e) => handleChange(e.target.value)}
          rows={4}
          disabled={!isActive}
          maxLength={max_size}
          placeholder={translate('POLLS.writeAnswer')}
          className="peer relative w-full resize-none rounded border border-slate-200 p-4 text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-gray-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-white disabled:text-slate-400"
        ></textarea>
        <label
          for={questionId}
          className="absolute left-2 -top-0 transform -translate-y-1/2 bg-white z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:top-0 before:left-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-base peer-required:after:text-pink-500 peer-required:after:content-['\u00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-gray-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
        >
          {translate('POLLS.writeAnswer')}
        </label>
        <small className="absolute flex w-full justify-end px-4 py-1 text-xs text-slate-400 transition peer-invalid:text-pink-500">
          <span className="text-slate-500">
            {' '}
            {answer.length}/{max_size}{' '}
          </span>
        </small>
      </div>
    </>
  );
};

export default FreeText;
