import * as ATypes from '../../shared/actionTypes';

var avatarImage = '/images2/profile/accountEmptyAvatarGradient.png';

export const profileReducer = (state, action) => {
  let defaultState = {
    profile: {
      nickname: null,
      name: null,
      surname: null,
      email: null,
      authority: null,
      country: null,
      avatar: avatarImage,
      full_profile: null,
      landline: null,
    },
    hasVerifiedEmail: false,
    hasVerifiedPhoneNumber: false,
  };

  if (!state) {
    state = defaultState;
  }

  switch (action.type) {
    case ATypes.POST_PROFILE_REQ:
      return {
        ...state,
      };
    case ATypes.POST_PROFILE_RES:
      return {
        ...state,
      };
    case ATypes.GET_PROFILE_REQ:
      return {
        ...state,
      };
    case ATypes.GET_PROFILE_RES:
      var newAvatar = avatarImage;
      if (action.payload.profile.avatar !== '') {
        newAvatar = action.payload.profile.avatar;
      }

      var newState = {
        profile: {
          ...state.profile,
          ...action.payload.profile,
          avatar: newAvatar,
        },
        hasVerifiedEmail: state.hasVerifiedEmail,
        hasVerifiedPhoneNumber: state.hasVerifiedPhoneNumber,
      };

      // if (action.payload.preserveProfileAuthorityAndCountry) {
      //     if (!action.payload.profile.authority || !action.payload.profile.country) {
      //         //see getProfile action for detailed comment
      //         if (state.profile.authority) {
      //             newState.profile.authority = state.profile.authority;
      //         }
      //         if (state.profile.country) {
      //             newState.profile.country = state.profile.country;
      //         }
      //     }
      // }

      return newState;
    case ATypes.CHECK_EMAIL_VERIFY_RES:
      return {
        ...state,
        hasVerifiedEmail: action.payload,
      };
    case ATypes.CHECK_MOBILE_VERIFY_RES:
      return {
        ...state,
        hasVerifiedPhoneNumber: action.payload,
      };
    // TODO: check if we need this case of loading the verification status
    case ATypes.GET_VERIFIED_ALL_REQ:
      return {
        ...state,
      };
    case ATypes.GET_VERIFIED_ALL_RES:
      return {
        ...state,
        hasVerifiedEmail: action.payload.hasVerifiedEmail,
        hasVerifiedPhoneNumber: action.payload.hasVerifiedPhoneNumber,
      };
    // TODO: check if we need this case of failure the verification status
    case ATypes.GET_VERIFIED_ALL_FAIL:
      return {
        ...state,
      };
    case 'persist/PURGE':
      return defaultState;

    default:
      return state;
  }
};
