import React from 'react';
import NOVOLoader from '@/shared/components/NOVOLoader';

const DiscussionsLoading = () => {
  return (
    <div className="fixed md:absolute w-full h-full flex flex-1 bg-black bg-opacity-5 z-50 pointer-events-none">
      <div
        className="absolute
        top-1/2
        left-1/2
        transform
        -translate-x-1/2
        -translate-y-1/2
        pointer-events-none"
      >
        <NOVOLoader
          style={{
            height: '4rem',
            width: '4rem',
          }}
        />
      </div>
    </div>
  );
};

export default DiscussionsLoading;
