import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getCameFrom } from '@/services/store';
import { NovovilleButton, Typography } from '@/components/novoville';
import {
  Card,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { toast } from '@/components/ui/use-toast';
import globe from '../../resources/globe.svg';
import novoville_logo from '../../resources/novoville_logo.svg';
import { getLanguage } from '../../services/GNRL';
import { translate } from '../../services/TranslationService';
import * as AnalyticsActions from '../../services/analyticsActions';
import { goTo, goToMainApp } from '../../shared/actions';
import AuthHeader from '../AuthHeader/AuthHeader';
import { getHomeData } from '../home/actions';
import { changeAndPostProfile } from '../profile/actions';
import { withNOVOHeader } from '../sideMenu/HeaderHOC';
import { setSelectedAuthority, setSelectedCountry } from './actions';

const excludeOtherCountryFromList = (countries) => {
  return countries?.filter((country) => country.name !== 'other');
};

const AuthorityComponent = (props) => {
  const dispatch = useDispatch();

  const allAuthorities = useSelector(
    (state) => state.countriesReducer.authorities_for_selected_country
  );

  const rawAllCountries = useSelector(
    (state) => state.countriesReducer.countries
  );

  const allCountries = excludeOtherCountryFromList(rawAllCountries);

  const selectedCountry = useSelector(
    (state) => state.countriesReducer.selected_country
  );

  const selectedAuthority = useSelector(
    (state) => state.countriesReducer.selected_authority
  );

  const currentAuthority = useSelector(
    (state) => state.profileReducer.profile.authority?.name
  );

  const currentCountry = useSelector(
    (state) => state.profileReducer.profile.country?.country_iso_code
  );

  useEffect(() => {
    if (selectedAuthority === null) {
      dispatch(setSelectedAuthority(currentAuthority));
    }
  }, [selectedAuthority, currentAuthority]);

  useEffect(() => {
    if (selectedCountry === currentCountry) {
      dispatch(setSelectedAuthority(currentAuthority));
      return;
    }

    dispatch(setSelectedAuthority(undefined));
  }, [selectedCountry]);

  const cameFrom = getCameFrom();

  const handleSetSelectedAuthority = (authorityName) => {
    dispatch(setSelectedAuthority(authorityName));
  };

  const handleSetSelectedCountry = (countryIsoCode) => {
    dispatch(setSelectedCountry(countryIsoCode));
  };

  const handleGoToMainApp = () => {
    dispatch(goToMainApp());
  };

  const handleGoTo = (destination) => {
    dispatch(goTo(destination));
  };

  const handleGetHomeData = () => {
    dispatch(getHomeData());
  };

  useEffect(() => {
    props?.setHeaderTitle?.(translate('country_authority'));
  }, []);

  const [loading, setLoading] = useState(false);
  const [, setLanguage] = useState(getLanguage());

  const handleLanguageChange = (newLanguage) => {
    setLanguage(newLanguage);
  };

  const authoritiesSection = (authorities, selectedAuthority) => {
    return authorities.map((authority) => (
      <SelectItem
        key={authority.name}
        value={authority.name}
        label={authority.translatedName}
      >
        {authority.translatedName}
      </SelectItem>
    ));
  };

  const countriesSection = (countries, selectedCountry) => {
    return countries.map((country) => (
      <SelectItem
        key={country.country_iso_code}
        value={country.country_iso_code}
        label={country.translatedName}
      >
        {country.translatedName}
      </SelectItem>
    ));
  };

  const postProfileAndContinue = async () => {
    setLoading(true);
    await dispatch(
      changeAndPostProfile({
        authority: selectedAuthority,
        country: selectedCountry,
      })
    )
      .then(() => {
        if (cameFrom === 'profile') {
          handleGetHomeData();
          handleGoTo('profile');
        } else if (cameFrom === 'myCity') {
          handleGoTo('myCity');
        } else {
          AnalyticsActions.onboardingSuccessEvent({
            selectedAuth: selectedAuthority,
          });
          handleGoToMainApp();
        }
      })
      .catch((err) => {
        let errDesc = _.get(
          err,
          'details.user_description',
          'An error ocurred'
        );

        if (cameFrom !== 'profile' && cameFrom !== 'myCity') {
          AnalyticsActions.onboardingSuccessEvent({
            selectedAuth: selectedAuthority,
            error: errDesc,
          });
        }
        alert(errDesc);
        setLoading(false);
      });
  };

  const { history } = props;

  useEffect(() => {
    if (cameFrom !== 'profile') {
      const unblock = history.block((location, action) => {
        if (action !== 'PUSH') {
          if (cameFrom === 'register') {
            toast({
              variant: 'destructive',
              title: translate('no_back'),
              description: translate('complete_process'),
            });
            return false;
          }
          return false;
        }
        return true;
      });
      return () => {
        unblock();
      };
    }
  }, [history, cameFrom]);

  const buttonDisabled = !selectedCountry || !selectedAuthority || loading;

  if (cameFrom === 'profile') {
    return (
      <div className="flex flex-wrap w-full h-full items-center justify-center">
        <Card className="w-fit min-w-[300px] self-center sm:p-[40px] drop-shadow-none shadow-none text-gray-800 bg-transparent border-none">
          <CardHeader className="text-center p-0 pb-[32px] flex flex-col items-center">
            <img
              src={globe}
              className="w-[130px] h-[130px] mb-[32px]"
              alt="Login"
            />
            <CardTitle className="text-3xl pb-[32px]">
              <Typography variant="semibold_24">
                {translate('country_authority')}
              </Typography>
              <Typography variant="regular_16">
                {translate('select_country_auth')}
                <br /> {translate('to_get_started')}
              </Typography>
            </CardTitle>
            <CardDescription className="mt-0 flex flex-col gap-4 min-w-[300px]">
              <Select
                value={selectedCountry}
                className="self-end"
                onValueChange={(e) => handleSetSelectedCountry(e)}
              >
                <SelectTrigger className="sm:w-[447px] min-w-[200px] border-[1px] border-gray-200 focus:border-strongCyan rounded-md">
                  <SelectValue placeholder={translate('select_country')} />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {countriesSection(allCountries, selectedCountry)}
                  </SelectGroup>
                </SelectContent>
              </Select>
              <Select
                value={selectedAuthority}
                onValueChange={(e) => handleSetSelectedAuthority(e)}
              >
                <SelectTrigger className="sm:w-[447px] w-[300px] border-[1px] border-gray-200 focus:border-strongCyan rounded-md">
                  <SelectValue placeholder={translate('select_authority')} />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {authoritiesSection(allAuthorities, selectedAuthority)}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </CardDescription>
          </CardHeader>
          <CardFooter className="p-0 flex flex-col justify-center items-center">
            <NovovilleButton
              variant="primary"
              disabled={buttonDisabled}
              text={translate('Continue')}
              onClick={postProfileAndContinue}
            />
          </CardFooter>
        </Card>
      </div>
    );
  } else {
    return (
      <div className="flex flex-wrap bg-white">
        <div className="h-[400vh] w-full min-h-[100vh] bg-white overflow-hidden fixed" />
        <AuthHeader
          logo={novoville_logo}
          handleLanguageChange={handleLanguageChange}
          disabled={cameFrom === 'profile' ? false : true}
        />
        <div className="min-h-[calc(100vh-100px)] w-screen flex flex-col justify-center sm:py-[74px] py-[24px] sm:px-8 px-[24px]">
          <Card className="w-fit min-w-[300px] self-center sm:p-[40px] sm:drop-shadow-md drop-shadow-none shadow-none text-gray-800 border-none">
            <CardHeader className="text-center p-0 pb-[32px] flex flex-col items-center">
              <img
                src={globe}
                className="w-[130px] h-[130px] mb-[32px]"
                alt="Login"
              />
              <CardTitle className="text-3xl pb-[32px]">
                <Typography variant="semibold_24">
                  {translate('country_authority')}
                </Typography>
                <Typography variant="regular_16">
                  {translate('select_country_auth')}
                  <br /> {translate('to_get_started')}
                </Typography>
              </CardTitle>
              <CardDescription className="mt-0 flex flex-col gap-4 min-w-[300px]">
                <Select
                  value={selectedCountry}
                  className="self-end"
                  onValueChange={(e) => {
                    handleSetSelectedCountry(e);
                  }}
                >
                  <SelectTrigger className="sm:w-[447px] min-w-[200px] border-[1px] border-gray-200 focus:border-strongCyan rounded-md">
                    <SelectValue placeholder={translate('select_country')} />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {countriesSection(allCountries, selectedCountry)}
                    </SelectGroup>
                  </SelectContent>
                </Select>
                <Select
                  value={selectedAuthority}
                  onValueChange={(e) => {
                    handleSetSelectedAuthority(e);
                  }}
                >
                  <SelectTrigger className="sm:w-[447px] w-[300px] border-[1px] border-gray-200 focus:border-strongCyan rounded-md">
                    <SelectValue placeholder={translate('select_authority')} />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {authoritiesSection(allAuthorities, selectedAuthority)}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </CardDescription>
            </CardHeader>
            <CardFooter className="p-0 flex flex-col justify-center items-center">
              <NovovilleButton
                variant="primary"
                disabled={buttonDisabled}
                text={translate('Continue')}
                onClick={postProfileAndContinue}
              />
            </CardFooter>
          </Card>
        </div>
      </div>
    );
  }
};

const AuthoritySelection = (props) => {
  const [AuthoritySelectionComponent, setAuthoritySelectionComponent] =
    useState(() => withRouter(AuthorityComponent));

  useEffect(() => {
    const cameFrom = getCameFrom();
    if (cameFrom === 'profile') {
      setAuthoritySelectionComponent(
        withNOVOHeader(withRouter(AuthorityComponent))
      );
    }
  }, []);

  return <AuthoritySelectionComponent {...props} />;
};

export default AuthoritySelection;
