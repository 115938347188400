import WEBAPI from '../../services/WEBAPI.js';
import * as ATypes from '../../shared/actionTypes';
import { getDerivedAuthority } from '../authoritySelection/utilities.js';

export const getDiscussions = () => {
  return async (dispatch, getState) => {
    const store = getState();
    const authorityName = getDerivedAuthority(store);

    dispatch({
      type: ATypes.GET_DISCUSSIONS_REQ,
    });

    try {
      const res = await WEBAPI.getDiscussions(authorityName);

      dispatch({
        type: ATypes.GET_DISCUSSIONS_RES,
        payload: res.result,
      });
    } catch (err) {
      console.log('err', err);
      dispatch({
        type: ATypes.GET_DISCUSSIONS_ERR,
        payload: err,
      });
    }
  };
};
