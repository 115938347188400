import EventsIcon from '@/resources/EventsIcon';
import React, { useEffect, useState } from 'react';
// TODO: replace momentjs with another library
import moment from 'moment';
import Calendar from 'react-calendar';
import { useDispatch, useSelector } from 'react-redux';
import { goTo } from '@/shared/actions';
import { getLanguage } from '@/services/GNRL';
import { translate } from '@/services/TranslationService';
import { Typography } from '@/components/novoville';
import { withNOVOHeader } from '../sideMenu/HeaderHOC';
import './Calendar.css';
import CalendarItem from './CalendarItem';
import { getCalendar } from './actions';

const NOVOCalendar = (props) => {
  const [selectedDay, setSelectedDay] = useState(moment());

  const [eventsForSelectedDay, setEventsForSelectedDay] = useState([]);

  const dispatch = useDispatch();

  const calendarData = useSelector(
    (state) => state.calendarReducer.calendarData
  );

  const [language, setLanguage] = useState(getLanguage());
  const { setHeaderTitle } = props;

  useEffect(() => {
    setHeaderTitle(translate('sideMenu.Calendar'));
    setLanguage(getLanguage());
    dispatch(getCalendar());
  }, [dispatch, language]);

  const dateChanged = (newDate) => {
    const selectedMoment = moment(newDate);
    const eventsOnThisDay = calendarData.filter((item) => {
      const startAtMoment = moment(item.start_at);
      return selectedMoment.isSame(startAtMoment, 'day');
    });
    setSelectedDay(selectedMoment);
    setEventsForSelectedDay(eventsOnThisDay);
  };
  const getTileClassName = ({ date, view }) => {
    if (view === 'month') {
      const currentMoment = moment(date).format('YYYY-MM-DD');
      const eventStartDate = calendarData.some((item) => {
        const startAtMoment = moment(item.start_at).format('YYYY-MM-DD');
        return currentMoment === startAtMoment;
      });
      if (eventStartDate) {
        return 'event-start-date';
      }
    }
    return '';
  };
  useEffect(() => {
    dateChanged(selectedDay);
  }, [calendarData]);

  return (
    <div className="flex flex-column flex-grow flex-1 sm:p-10">
      <div className="p-8 bg-white lg:max-h-[calc(100vh-10rem)] w-full flex">
        <div className="flex flex-1 flex-col lg:flex-row">
          <div className="calendar mx-auto">
            <Calendar
              onChange={dateChanged}
              showToday={false}
              showDateInput={false}
              next2Label={null}
              prev2Label={null}
              value={selectedDay.toDate()}
              tileClassName={getTileClassName}
              locale={language === 'gr' ? 'el' : 'en'}
            />
          </div>
          <div className="calendar-content h-full flex-1 flex justify-center ">
            {eventsForSelectedDay.length === 0 && (
              <div className="calendar-content-not-exist flex flex-1 flex-col items-center m-auto lg:mt-auto mt-3">
                <EventsIcon />
                <Typography
                  variant="semibold_16"
                  className="text-gray-500 text-center"
                >
                  {translate('no_events_today')}
                </Typography>
              </div>
            )}
            {eventsForSelectedDay.length > 0 && (
              <div className="calendar-content-exist overflow-y-auto ">
                {eventsForSelectedDay.map((item) => {
                  return (
                    <CalendarItem
                      key={item.id}
                      event={item}
                      onClick={() => {
                        dispatch(
                          goTo('calendar/' + item.id, null, {
                            message: item,
                            cameFrom: 'CALENDAR',
                          })
                        );
                      }}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withNOVOHeader(NOVOCalendar);
