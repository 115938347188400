import React from 'react';
import { Circle } from 'lucide-react';
import { Typography } from '@/components/novoville';

const OneAnswer = ({ question, handleChange, isActive }) => {
  const { id: questionId, answers, answer, stats } = question;

  return (
    <ul className="list-none flex flex-col space-y-4">
      {answers.map(({ id, value }) => (
        <li key={id}>
          <label
            className={`relative flex w-fit items-center ${isActive ? 'cursor-pointer' : 'cursor-not-allowed'}`}
          >
            {!isActive && stats && (
              <Typography
                variant="regular_14"
                className="w-16 font-semibold text-gray-900"
              >
                {stats[id]}%
              </Typography>
            )}
            <input
              type="radio"
              name={questionId}
              className="sr-only peer"
              checked={answer === id}
              disabled={!isActive}
              onChange={() => handleChange(id)}
            />
            <span
              className="flex h-4 w-4 rounded-full border border-gray-300 mr-2 items-center justify-center  
              text-transparent
              peer-checked:border-black peer-checked:text-black 
              peer-disabled:border-gray-200"
            >
              <Circle className="h-2/3 w-2/3 fill-current text-inherit" />
            </span>
            <Typography
              variant="regular_16"
              className="text-gray-900 peer-disabled:text-gray-500"
            >
              {value}
            </Typography>
          </label>
        </li>
      ))}
    </ul>
  );
};

export default OneAnswer;
