import React, { useState } from 'react';
import { Eye, EyeOff } from 'lucide-react';
import { getLanguage } from '@/services/GNRL';
import {
  NovovilleButton,
  NovovilleInput,
  Typography,
} from '@/components/novoville';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { Label } from '@/components/ui/label';
import { Separator } from '@/components/ui/separator';
import '../../output.css';
import novoville_logo from '../../resources/novoville_logo.svg';
import AuthHeader from '../AuthHeader/AuthHeader';

const Login = ({
  errorMessage,
  loading,
  email,
  password,
  fieldsValid,
  forgotPassUrl,
  onChange,
  login,
  forgotPassword,
  translate,
}) => {
  const [language, setLanguage] = useState(getLanguage());
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleLanguageChange = (newLanguage) => {
    setLanguage(newLanguage);
  };

  return (
    <div className="flex flex-wrap bg-white">
      <div className="h-[400vh] w-full min-h-[100vh] bg-white overflow-hidden fixed" />
      <AuthHeader
        logo={novoville_logo}
        handleLanguageChange={handleLanguageChange}
      />
      <div className="min-h-[calc(100vh-100px)] w-screen flex flex-col justify-center sm:py-[74px] py-[24px] sm:px-8">
        <form
          onSubmit={(e) => {
            login('EMAIL');
            e.preventDefault();
          }}
          className="w-full max-w-md self-center"
        >
          <Card className="w-full sm:p-[40px] sm:drop-shadow-md drop-shadow-none shadow-none text-gray-800 px-[24px] border-none">
            <CardHeader className="text-center p-0 pb-[32px]">
              <CardTitle className="text-3xl pb-[12px]">
                <Typography variant="semibold_24">
                  {translate('Login')}
                </Typography>
              </CardTitle>
              <CardDescription className="mt-0">
                <Typography variant="regular_16">
                  {translate('login_details')}
                </Typography>
              </CardDescription>
            </CardHeader>
            <CardContent className="p-0">
              <div className="pb-[24px] grid gap-2">
                <Label htmlFor="email" className={'leading-[20px] font-medium'}>
                  Email
                </Label>
                <NovovilleInput
                  id="email"
                  type="email"
                  name="username"
                  autocomplete="username"
                  autofocus={true}
                  value={email}
                  onChange={(e) => onChange('email', e.target.value)}
                />
              </div>
              <div className="grid gap-2 pb-[16px] relative">
                <Label
                  htmlFor="password"
                  className={`leading-[20px] font-medium ${
                    errorMessage ? 'text-errorRed' : ''
                  }`}
                >
                  {translate('Password')}
                </Label>
                <NovovilleInput
                  id="password"
                  type={passwordVisible ? 'text' : 'password'}
                  name="password"
                  value={password}
                  onChange={(e) => onChange('password', e.target.value)}
                  errorMessage={errorMessage}
                  iconRight={
                    passwordVisible ? <Eye size={20} /> : <EyeOff size={20} />
                  }
                  onIconClick={togglePasswordVisibility}
                  required
                />
              </div>
              {errorMessage && (
                <div style={{ wordWrap: 'break-word' }}>
                  <Typography
                    variant="regular_14"
                    className="text-errorRed"
                    error={!!errorMessage}
                  >
                    {translate('invalid_cred')}
                  </Typography>
                </div>
              )}
              <div className="grid gap-2">
                <NovovilleButton
                  onClick={forgotPassword}
                  variant="link"
                  text={translate('forgot_password')}
                  className="underline pb-[32px] text-gray-700 hover:text-gray-600 justify-self-start"
                  to={forgotPassUrl || '/forgot-password'}
                />
              </div>
            </CardContent>
            <CardFooter className="p-0 flex flex-col justify-center items-center">
              <NovovilleButton
                disabled={loading || !fieldsValid}
                onClick={() => login('EMAIL')}
                variant="primary"
                text={translate('Login')}
              />
              <div className="w-full py-[32px] overflow-hidden flex flex-row justify-center items-center">
                <Separator className="w-full" />
                <Typography
                  variant="regular_14"
                  className="text-nowrap px-[10px] w-full text-gray-700"
                >
                  {translate('login_with')}
                </Typography>
                <Separator className="w-full" />
              </div>
              <div className="w-full pb-[16px]">
                <NovovilleButton
                  disabled={loading}
                  onClick={() => login('FACEBOOK')}
                  social="Facebook"
                  variant="secondary"
                  text={translate('continue_facebook')}
                />
              </div>
              <div className="w-full pb-[32px]">
                <NovovilleButton
                  disabled={loading}
                  onClick={() => login('GOOGLE')}
                  social="Google"
                  variant="secondary"
                  text={translate('continue_google')}
                />
              </div>
              <div
                className={`w-full text-nowrap py-[32px] gap-[4px] sm:py-[0px] px-[16px] flex flex-col justify-center items-center text-gray-700 ${
                  language === 'gr' ? 'sm:flex-col' : 'sm:flex-row'
                }`}
              >
                <Typography variant="regular_16">
                  {translate('no_acc')}
                </Typography>
                <NovovilleButton
                  variant="link"
                  text={translate('create_novoville_acc')}
                  className="underline leading-[24px]"
                  to="/register"
                />
              </div>
            </CardFooter>
          </Card>
        </form>
      </div>
    </div>
  );
};

export default Login;
