import { get } from 'lodash';
import WEBAPI from '../../services/WEBAPI.js';
import * as AnalyticsActions from '../../services/analyticsActions';
import { setItem } from '../../services/localStorageWrap';
import * as ATypes from '../../shared/actionTypes';
import {
  getAuthorityDetails,
  getPermissions,
  goToAuthorityOrMainApp,
} from '../../shared/actions';
import { getModules, getPlacemarks } from '../../shared/actions';
import { setFilters } from '../myCity/actions.js';
import {
  doesUserHaveAuthoritySetRemotely,
  getProfile,
} from '../profile/actions';
import { changeAndPostProfile } from '../profile/actions';
import { postAccount } from '../register/actions';

export const login = (
  email,
  password,
  loginType,
  application,
  isUserUnauth,
  authority,
  country
) => {
  //TODO: MOVE ALL WEBAPI calls into one function.
  return (dispatch, getState) => {
    // FIRST GET ANY URL PARAMS THAT MIGHT HAVE BEEN SET
    let setAuthorityBecauseOfURLParam = get(
      getState(),
      'otherReducer.params.setAuthority'
    );
    let setCountryBecauseOfURLParam = get(
      getState(),
      'otherReducer.params.setCountry'
    );
    // let goToPageBecauseOfUrlParam = get(getState(), "otherReducer.params.goToPage")

    dispatch({
      type: ATypes.POST_LOGIN_REQ,
    });

    if (loginType === 'FACEBOOK') {
      return loginWithFB().then((fbData) => {
        return WEBAPI.postLogin(
          fbData.email,
          'RANDOM_WHATEVER',
          fbData.socialtoken,
          fbData.name,
          fbData.surname,
          'facebook',
          application
        )
          .then((res) => {
            setItem('hasRegistered', true);
            AnalyticsActions.identify(res.result.user_sso_id);

            dispatch({
              type: ATypes.POST_LOGIN_RES,
              payload: {
                user_sso_id: res.result.user_sso_id,
                token: res.result.token,
                login_type: 'facebook',
              },
            });

            let profileDataToSend = {
              name: fbData.name,
              surname: fbData.surname,
              email: fbData.email,
              avatar: fbData.avatarURL,
            };
            // IF THE AUTHORITY HAS BEEN SET IN THE URL THEN SET IT TO THE PROFILE
            if (res.result.first_time) {
              // PRWTI FORA
              setItem('hasRegistered', true);
              AnalyticsActions.registration({
                type: 'facebook',
                email: fbData.email,
                firstName: fbData.name,
                lastName: fbData.surname,
                isUserUnauth: !!isUserUnauth,
                application,
              });
              //this is only webapp right now
              if (
                setAuthorityBecauseOfURLParam &&
                setCountryBecauseOfURLParam
              ) {
                profileDataToSend = {
                  ...profileDataToSend,
                  authority: setAuthorityBecauseOfURLParam,
                  country: setCountryBecauseOfURLParam,
                };
              }
            } else {
              AnalyticsActions.loginEvent({
                type: 'facebook',
                email: fbData.email,
                application,
                isUserUnauth: !!isUserUnauth,
              });
            }
            return handleUnauthUserProfileManagement({
              isUserUnauth: isUserUnauth || res.result.first_time,
              authority: authority || { name: setAuthorityBecauseOfURLParam },
              country: country || {
                country_iso_code: setCountryBecauseOfURLParam,
              },
              first_time_registration: res.result.first_time,
              extraProfileData: profileDataToSend,
              dispatch,
            });

            // performActionBasedOnURLParams(getState, dispatch, res.result.token)
          })
          .catch((err) => {
            AnalyticsActions.registration({
              type: 'facebook',
              isUserUnauth: !!isUserUnauth,
              error: get(err, 'details.user_description', 'An error ocurred'),
            });
            throw err;
          });
      });
    }

    if (loginType === 'GOOGLE') {
      return signInGoogle()
        .then((googleData) => {
          return WEBAPI.postLogin(
            googleData.email,
            'RANDOM_WHATEVER',
            googleData.socialtoken,
            googleData.name,
            googleData.surname,
            'google',
            application
          ).then((res) => {
            setItem('hasRegistered', true);
            AnalyticsActions.identify(res.result.user_sso_id);

            dispatch({
              type: ATypes.POST_LOGIN_RES,
              payload: {
                user_sso_id: res.result.user_sso_id,
                token: res.result.token,
                login_type: 'google',
              },
            });

            let profileDataToSend = {
              name: googleData.name,
              surname: googleData.surname,
              email: googleData.email,
              avatar: googleData.avatarURL,
            };
            // IF THE AUTHORITY HAS BEEN SET IN THE URL THEN SET IT TO THE PROFILE
            if (res.result.first_time) {
              setItem('hasRegistered', true);
              AnalyticsActions.registration({
                type: 'google',
                email: googleData.email,
                firstName: googleData.name,
                lastName: googleData.surname,
                isUserUnauth: !!isUserUnauth,
                application,
              });
              if (
                setAuthorityBecauseOfURLParam &&
                setCountryBecauseOfURLParam
              ) {
                profileDataToSend = {
                  ...profileDataToSend,
                  authority: setAuthorityBecauseOfURLParam,
                  country: setCountryBecauseOfURLParam,
                };
              }
            } else {
              AnalyticsActions.loginEvent({
                type: 'google',
                email: googleData.email,
                application,
                isUserUnauth: !!isUserUnauth,
              });
            }

            return handleUnauthUserProfileManagement({
              isUserUnauth: isUserUnauth || res.result.first_time,
              authority: authority || { name: setAuthorityBecauseOfURLParam },
              country: country || {
                country_iso_code: setCountryBecauseOfURLParam,
              },
              first_time_registration: res.result.first_time,
              extraProfileData: profileDataToSend,
              dispatch,
            });
            // performActionBasedOnURLParams(getState, dispatch, res.result.token)
          });
        })
        .catch((err) => {
          AnalyticsActions.registration({
            type: 'google',
            isUserUnauth: !!isUserUnauth,
            error: get(err, 'details.user_description', 'An error ocurred'),
          });
          throw err;
        });
    }

    if (loginType === 'APPLE') {
      return signInWithApple()
        .then((appleData) => {
          return WEBAPI.postLogin(
            appleData.email,
            'RANDOM_WHATEVER',
            appleData.socialtoken,
            appleData.name || 'Name',
            appleData.surname || 'Surname',
            'apple',
            application
          ).then((res) => {
            AnalyticsActions.identify(res.result.user_sso_id);

            dispatch({
              type: ATypes.POST_LOGIN_RES,
              payload: {
                user_sso_id: res.result.user_sso_id,
                token: res.result.token,
                login_type: 'apple',
                appleUser: appleData.appleUser,
              },
            });

            let profileDataToSend = {
              name: appleData.name,
              surname: appleData.surname,
              email: appleData.email,
              avatar: appleData.avatarURL,
            };
            // IF THE AUTHORITY HAS BEEN SET IN THE URL THEN SET IT TO THE PROFILE
            if (res.result.first_time) {
              setItem('hasRegistered', true);
              AnalyticsActions.registration({
                type: 'apple',
                email: appleData.email,
                firstName: appleData.name,
                lastName: appleData.surname,
                isUserUnauth: !!isUserUnauth,
                application,
              });
              if (
                setAuthorityBecauseOfURLParam &&
                setCountryBecauseOfURLParam
              ) {
                profileDataToSend = {
                  ...profileDataToSend,
                  authority: setAuthorityBecauseOfURLParam,
                  country: setCountryBecauseOfURLParam,
                };
              }
            } else {
              setItem('hasRegistered', true);

              AnalyticsActions.loginEvent({
                type: 'apple',
                email: appleData.email,
                application,
                isUserUnauth: !!isUserUnauth,
              });
            }

            return handleUnauthUserProfileManagement({
              isUserUnauth: isUserUnauth || res.result.first_time,
              authority: authority || { name: setAuthorityBecauseOfURLParam },
              country: country || {
                country_iso_code: setCountryBecauseOfURLParam,
              },
              first_time_registration: res.result.first_time,
              extraProfileData: profileDataToSend,
              dispatch,
            });
            // performActionBasedOnURLParams(getState, dispatch, res.result.token)
          });
        })
        .catch((err) => {
          AnalyticsActions.registration({
            type: 'apple',
            isUserUnauth: !!isUserUnauth,
            error: get(err, 'details.user_description', 'An error ocurred'),
          });
          throw err;
        });
    }

    if (!loginType || loginType === 'email') {
      return WEBAPI.postLogin(
        email,
        password,
        null,
        null,
        null,
        null,
        application
      )
        .then((res) => {
          //after registration a login always follows so since all other hasRegistered are here will add this here too
          setItem('hasRegistered', true);

          AnalyticsActions.identify(res.result.user_sso_id);

          dispatch({
            type: ATypes.POST_LOGIN_RES,
            payload: {
              user_sso_id: res.result.user_sso_id,
              token: res.result.token,
              login_type: 'email',
            },
          });
          AnalyticsActions.loginEvent({
            type: 'email',
            email,
            application,
            isUserUnauth: !!isUserUnauth,
          });
          // IF THE AUTHORITY HAS BEEN SET IN THE URL THEN SET IT TO THE PROFILE. DISABLED FOR NOW
          // if (setAuthorityBecauseOfURLParam && setCountryBecauseOfURLParam) {
          //     let profileDataToSend = { authority: setAuthorityBecauseOfURLParam, country: setCountryBecauseOfURLParam }
          //     return dispatch(changeAndPostProfile(profileDataToSend))
          // }
          return handleUnauthUserProfileManagement({
            isUserUnauth,
            authority,
            country,
            first_time_registration: false,
            dispatch,
            extraProfileData: { email },
          });
          // performActionBasedOnURLParams(getState, dispatch, res.result.token)
        })
        .catch((err) => {
          AnalyticsActions.loginEvent({
            type: 'email',
            email,
            application,
            isUserUnauth: !!isUserUnauth,
            error: get(err, 'details.user_description', 'An error ocurred'),
          });
          throw err;
        });
    }
  };
};

function handleUnauthUserProfileManagement({
  isUserUnauth,
  authority,
  country,
  first_time_registration,
  dispatch,
  extraProfileData = {},
} = {}) {
  if (
    isUserUnauth &&
    authority &&
    authority.name &&
    country &&
    country.country_iso_code
  ) {
    // return dispatch(getProfile({ preserveProfileAuthorityAndCountry: true })).then((profile) => {
    return dispatch(doesUserHaveAuthoritySetRemotely())
      .then((userRemoteAuth) => {
        if (!userRemoteAuth) {
          AnalyticsActions.onboardingSuccessEvent({
            selectedAuth: authority.name,
          });
          return dispatch(
            changeAndPostProfile({
              authority: authority.name,
              country: country.country_iso_code,
              ...extraProfileData,
            })
          );
        } else {
          return dispatch(getProfile()).then(() => {
            return { isRegistrationDueToSocial: first_time_registration };
          });
        }
      })
      .then(() => {
        // return dispatch(getProfile()).then(() => {
        return { isRegistrationDueToSocial: first_time_registration };
        // })
      });
    // })
  } else {
    return dispatch(getProfile()).then(() => {
      return { isRegistrationDueToSocial: first_time_registration };
    });
  }
}

const loginWithFB = () => {
  return new Promise((res, rej) => {
    window.FB.login(
      function (response) {
        if (response.authResponse) {
          window.FB.api(
            '/me',
            {
              fields: 'id,name,email',
            },
            function (response2) {
              if (response2 && !response2.error) {
                var avatarURL =
                  'https://graph.facebook.com/' +
                  response2['id'] +
                  '/picture?type=large&w‌​idth=720&height=720';
                var socialtoken = response.authResponse.accessToken;

                var { name, surname } = getFacebookNameParts(response2.name);

                var facebookData = {
                  avatarURL,
                  socialtoken,
                  email: response2.email,
                  name: name,
                  surname: surname ? surname : name,
                };

                res(facebookData);
              } else {
                rej();
              }
            }
          );
        } else {
          //TODO ERROR HANDLING
          rej();
        }
      },
      { scope: 'email,public_profile' }
    );
  });
};

const signInGoogle = () => {
  return new Promise((res, rej) => {
    var options = new window.gapi.auth2.SigninOptionsBuilder();
    options.setScope('profile').setScope('email');

    window.auth2
      .signIn(options)
      .then((user) => {
        var basicProfile = user.getBasicProfile();
        var authData = user.getAuthResponse(true);

        let name = basicProfile.getGivenName();
        let surname = basicProfile.getFamilyName();
        var profileData = {
          email: basicProfile.getEmail(),
          name,
          surname: surname ? surname : name,
          avatar: basicProfile.getImageUrl(),
          socialtoken: authData.id_token,
        };
        res(profileData);
      })
      .catch((err) => {
        rej(err);
      });
  });
};

const signInWithApple = async () => {
  // performs login request
  // appleAuthRequestResponse data structure:
  // authorizationCode: "c1e5df375971a42928a2932860bbb638f.0.nrut.1bGhEHxf85ly0hvAAF17fg"
  // authorizedScopes: []
  // email: null
  // fullName: {namePrefix: null, givenName: null, nameSuffix: null, nickname: null, familyName: null, …}
  // identityToken: "eyJraWQiOiI4NkQ4OEtmIiwiYWxnIjoiUlMyNTYifQ.eyJpc3MiOiJodHRwczovL2FwcGxlaWQuYXBwbGUuY29tIiwiYXVkIjoiY29tLm5vdm92aWxsZS1kZXYiLCJleHAiOjE1OTQwNDIwNTgsImlhdCI6MTU5NDA0MTQ1OCwic3ViIjoiMDAwMTQzLjQ5NzU0ZDcyNTMyZDQ5NTQ4ZWI5N2ZmM2EyNDhlMDIyLjExMzQiLCJub25jZSI6ImM5MWRkYTYzOGI2MjQ2ZTk3M2QwNzc0ZDU3ZWUxMzg0ODVjN2UzZWI3NGQ4NGRmOGIyZWMwMjEyNzJlNDRiMTYiLCJjX2hhc2giOiJaSTEyUlpIcWhtNjk5QkZzRGxmeVpRIiwiZW1haWwiOiJ1OWg2aXp6a2E2QHByaXZhdGVyZWxheS5hcHBsZWlkLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjoidHJ1ZSIsImlzX3ByaXZhdGVfZW1haWwiOiJ0cnVlIiwiYXV0aF90aW1lIjoxNTk0MDQxNDU4LCJub25jZV9zdXBwb3J0ZWQiOnRydWV9.Igz04WLgrAq72MI55caQXDZIJ3RcGYwi_CAC3b07gEYhWAJYGfbAUTd0OYyB7o47XJm65CJaJ2w_cGDUu9Q8BrD0G3ZIQFGDfdzbZHOZ7BnfzCmYfQC5pjjR1mHuEXBnpHwTly0t1wenXHMhonS1jE2NaBYO5IAZGY0pQNR_ehVTmKugSrUrbK10Udrkase83Dn80W_vNsrfZSulZPMb4I-Gs_3Zh6sHs_sKURcPHJzyX2UEQ_a9pZ-Iod8hkAbZLlke4ID5-ybs9admA3WgXs8r2hYHUC1ZeUacqgcRPUWRxvjJoJ4iuLbwb3CnMN-Q27_XURdcOvlZFaO-pjDb8w"
  // nonce: "pc7s_uzB74J-_8v_XR-cecpp9RKUJHHS"
  // realUserStatus: 1
  // state: null
  // user: "000143.49754d72532d49548eb97ff3a248e022.1134"
};

//COMBINED ACTIONS
export const loginProcessAndRoute = (email, password, loginType) => {
  return (dispatch, getState) => {
    // TODO: NOT YET IMPLEMENTED, ONLY SUPPORTING REGISTER FOR URL PARMS RIGHT NOW
    // let goToPageBecauseOfUrlParam = get(getState(), "otherReducer.params.goToPage")

    return dispatch(loginProcess(email, password, loginType))
      .then(() => {
        const isMyCityModuleEnabled = getState()?.otherReducer?.modules.find(
          (item) => item.name === 'mycity'
        );
        const authority = getState()?.profileReducer?.profile?.authority?.name;
        if (isMyCityModuleEnabled && authority) {
          return dispatch(getPlacemarks());
        }
      })
      .then(() => {
        // if (!goToPageBecauseOfUrlParam) {

        dispatch(setFilters([{
          name: 'REPORTS'
        }]));
        return dispatch(goToAuthorityOrMainApp());
        // } else {
        //     return dispatch(goTo(goToPageBecauseOfUrlParam))
        // }
      });
  };
};

export const loginProcess = (
  email,
  password,
  loginType,
  application,
  isUserUnauth,
  authority,
  country
) => {
  return (dispatch, getState) => {
    // TODO: NOT YET IMPLEMENTED, ONLY SUPPORTING REGISTER FOR URL PARMS RIGHT NOW
    // let goToPageBecauseOfUrlParam = get(getState(), "otherReducer.params.goToPage")

    return dispatch(
      login(
        email,
        password,
        loginType,
        application,
        isUserUnauth,
        authority,
        country
      )
    )
      .then((loginRes) => {
        //this is driving whether the onboarding screen displays or not
        setItem('parkingLoggedInOnce', true);

        let accountPromise = dispatch(postAccount());

        let initialModule;
        return dispatch(getAuthorityDetails())
          .then((res) => {
            initialModule = res?.initial_module;
            return dispatch(getPermissions());
          })
          .then(() => {
            return dispatch(getModules()).then((modules) => {
              let authority = get(
                getState(),
                'profileReducer.profile.authority'
              );
              return { ...loginRes, authority, modules, initialModule };
            });
          });
      })
      .catch((err) => {
        console.log('error in login process');
        console.log(err);
        throw err;
      });
  };
};

//APPTIVILLE

function performActionBasedOnURLParams(getState, dispatch, token) {
  let redirect_url = get(getState(), 'otherReducer.params.redirect_url');

  if (redirect_url) {
    redirect_url = redirect_url + '?token=' + token;
    // TODO: CONSIDER SAFETY HERE
    // WHAT IF AN ATTACKER SENDS A URL OUT TO SOMEONE
    // AND REDIRECTS TO THEIR OWN SITE, GET THE TOKEN AND THEN
    // REDIRECTS TO A LEGIT LOOKING SITE?
    // apptivilleProcess(redirect_url, dispatch)
    return;
  }
}

function apptivilleProcess(redirect_url, dispatch) {
  //TODO: BELOW DISPATCH IS PROBABLY REDUNDANT AS PERSIST IS ON BLACK LIST FOR THIS REDUCER
  dispatch({
    type: ATypes.UNSET_ORIGINAL_URL_PARAMS,
  });
  if (redirect_url) {
    //TODO: FIND A BETTER WAY TO DO THIS. THE ISSUE WITH THIS IS THAT
    //WINDOW.LOCATION.REPLACE TAKES SOME TIME TO TAKE EFFECT AND DURING THAT
    //i could throw an error or just do what i do below but document.write and replace
    //arent clear whether they re sync or not.
    // window.document.write("loading")
    // setTimeout(function () {
    window.location.replace(redirect_url);
    // }, 5000)
    let a = new Error();
    throw (a = { details: { user_description: 'Error loging in' } });
  }
}

export const sendTokenToAptiville = (token) => {};

const getFacebookNameParts = (facebookName) => {
  let splitted = facebookName.split(' ');
  let retObj = {
    name: 'Unnamed',
    surname: 'Unnamed',
  };

  if (splitted.length === 1) {
    retObj.name = splitted[0];
    retObj.surname = splitted[0];
  }

  if (splitted.length > 1) {
    retObj.name = splitted[0];
    retObj.surname = splitted[1];
  }

  return retObj;
};
