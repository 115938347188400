import React from 'react';
import { Typography } from '@/components/novoville';

const MultipleAnswers = ({ question, handleChange, isActive }) => {
  const { answers, id: questionId, answer, stats } = question;

  const answerArray = answer ? answer.split(',') : [];

  const handleCheckboxChange = (e) => {
    const { value } = e.target;
    const isChecked = answerArray.includes(value);

    if (isChecked) {
      const newAnswersArray = answerArray.filter((answer) => answer !== value);
      return handleChange(newAnswersArray.join(','));
    }

    return handleChange([...answerArray, value].join(','));
  };

  return (
    <ul className="list-none flex flex-col space-y-4">
      {answers.map((answer) => {
        const { id, value } = answer;
        return (
          <li key={id}>
            <label
              className={`flex w-fit items-center space-x-2  ${
                isActive ? 'cursor-pointer' : 'cursor-not-allowed'
              }`}
            >
              {!isActive && stats && (
                <Typography
                  variant="regular_14"
                  className="w-16 font-semibold text-gray-900"
                >
                  {stats[id]}%
                </Typography>
              )}
              <input
                id={id}
                type="checkbox"
                checked={answerArray.includes(id)}
                onChange={handleCheckboxChange}
                name={questionId}
                value={id}
                disabled={!isActive}
                className="peer h-4 w-4 border
                border-slate-200  accent-strongCyan
                rounded transition-all cursor-pointer 
                focus:ring-1 focus:ring-strongCyan focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 
                focus-visible:ring-strongCyan focus-visible:ring-opacity-50"
              />
              <Typography
                variant="regular_16"
                className="text-gray-900 peer-disabled:text-gray-500"
              >
                {value}
              </Typography>
            </label>
          </li>
        );
      })}
    </ul>
  );
};

export default MultipleAnswers;
