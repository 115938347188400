import WEBAPI from '../../services/WEBAPI';
import { getAuthorityForCurrentApplication } from '../../services/platformCheck';
import * as ATypes from '../../shared/actionTypes';

export const getCalendar = (id) => {
  return (dispatch, getState) => {
    const authority =
      getAuthorityForCurrentApplication() ??
      getState()?.profileReducer?.profile?.authority?.name;

    dispatch({
      type: ATypes.GET_CALENDAR_REQ,
    });

    return WEBAPI.getCalendar(authority).then((res) => {
      dispatch({
        type: ATypes.GET_CALENDAR_RES,
        payload: res.result.data || [],
      });
    });
  };
};

const getCalendarRequest = () => ({
  type: ATypes.GET_CALENDAR_EVENT_REQ,
});

const getCalendarEventSuccess = (event) => ({
  type: ATypes.GET_CALENDAR_EVENT_RES,
  payload: event,
});

const getCalendarEventFailure = (error) => ({
  type: ATypes.GET_CALENDAR_EVENT_FAIL,
  payload: error,
});

export const getCalendarEvent = (id) => async (dispatch, getState) => {
  dispatch(getCalendarRequest());
  const authority =
    getAuthorityForCurrentApplication() ??
    getState()?.profileReducer?.profile?.authority?.name;

  try {
    const response = await WEBAPI.getCalendarEventByIdRequest(authority, id);
    console.log(response);

    if (response?.error) {
      throw new Error('Failed to load calendar item details. Try again later.');
    }

    const calendarEvent = response?.result?.data[0] ?? {};
    dispatch(getCalendarEventSuccess(calendarEvent));
  } catch (error) {
    dispatch(getCalendarEventFailure(error));
  }
};
