import { formatPhoneNumber } from '@/lib/utils';
import WEBAPI from '../../services/WEBAPI';
import { goTo, replaceRouter } from '../../shared/actions';
import { postUserProfile } from '../profile/actions';

export const verify = (mobileOrEmail, token) => {
  return (dispatch, getState) => {
    return WEBAPI.verify(mobileOrEmail, token);
  };
};

//TODO
export const resendPin = () => {
  return (dispatch, getState) => {};
};
//COMBINED ACTIONS

export const verifyAndAddToProfile = (
  mobileOrEmail,
  token,
  cameFrom,
  phoneVerified
) => {
  return (dispatch, getState) => {
    return dispatch(
      verify(
        formatPhoneNumber({ phoneNumber: mobileOrEmail, needsPlusSign: true }),
        token
      )
    )
      .then(() =>
        dispatch(
          postUserProfile({
            mobile: formatPhoneNumber({
              phoneNumber: mobileOrEmail,
              needsPlusSign: true,
            }),
          })
        )
      )
      .then(() => {
        if (phoneVerified) {
          phoneVerified(mobileOrEmail);
        } else {
          if (!cameFrom) {
            return dispatch(goTo('profile'));
          } else {
            return dispatch(goTo(cameFrom));
          }
        }
      });
  };
};
