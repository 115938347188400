import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { FileText } from 'lucide-react';
import { connect, useSelector } from 'react-redux';
import { NovovilleButton, Typography } from '@/components/novoville';
import { translate } from '../../services/TranslationService';
import { goTo } from '../../shared/actions';
import VerifyModal from '../modal/VerifyModal';

const QuickActionsCard = ({
  basicColor,
  title,
  subTitle,
  icon,
  cardAction,
}) => {
  return (
    <div className="flex flex-1 h-full flex-col rounded-[16px] bg-gray-50 items-center justify-center p-[24px]">
      <div
        className={`bg-${basicColor} rounded-full w-[40px] h-[40px] flex items-center justify-center`}
      >
        {icon}
      </div>
      <Typography variant="bold_24" className="pb-[4px] pt-[16px]">
        {title}
      </Typography>
      <Typography
        variant="regular_16"
        className="pb-[24px] text-center text-gray-700"
      >
        {subTitle}
      </Typography>
      {cardAction}
    </div>
  );
};

const QuickActionsSection = ({
  hasVerifiedEmail,
  hasVerifiedPhoneNumber,
  goTo,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClickNewReport = () => {
    if (hasVerifiedEmail && hasVerifiedPhoneNumber) {
      goTo('newReport');
      return setIsModalOpen(false);
    }
  };

  const { modules } = useSelector((state) => ({
    modules: state.otherReducer?.modules,
  }));

  const modulesNamesArray = modules.map((module) => module.name);
  // { name: 'discussions', enabled: true }
  const reportModuleEnabled = modulesNamesArray.includes('reports');

  const discussionModuleEnabled = modulesNamesArray.includes('discussions');

  return (
    <div className="flex">
      <div className="flex flex-col bg-white w-full sm:p-[32px] p-[24px] sm:rounded-[16px] rounded-0 gap-[24px]">
        <div className="flex sm:flex-row md:flex-row flex-col items-center justify-between ">
          <Typography variant="bold_20" className="text-center text-gray-900">
            {translate('Simplifying City Living')}
          </Typography>
        </div>
        <div
          className="flex sm:flex-row md:flex-row flex-col items-center justify-between 
                      space-x-o sm:space-x-2 space-y-4 sm:space-y-0"
        >
          {reportModuleEnabled && (
            <QuickActionsCard
              basicColor="strongCyan"
              title={translate('Reports')}
              subTitle={translate("Let's take proactive steps")}
              icon={<FileText className="w-[24px] h-[24px]" />}
              cardAction={
                <VerifyModal
                  onClick={handleClickNewReport}
                  onOpenChange={setIsModalOpen}
                  shouldOpen={isModalOpen}
                >
                  {translate('newReportLower')}
                </VerifyModal>
              }
            />
          )}
          {discussionModuleEnabled && (
            <QuickActionsCard
              basicColor="lightPurple"
              title={translate('HOME.consultationBanner')}
              subTitle={translate('HOME.consultationDescription')}
              icon={<FileText className="w-[24px] h-[24px]" />}
              cardAction={
                <NovovilleButton
                  onClick={() => goTo('discussions')}
                  className="w-fit min-w-[170px] bg-lightPurple active:bg-lightPurple hover:bg-purpleLink hover:text-white"
                  variant="primary"
                >
                  {translate('HOME.consultationAction')}
                </NovovilleButton>
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

var mapStateToProps = (state) => {
  return {
    solved: state.homeReducer.no_completed,
    pending: state.homeReducer.no_pending,
    hasVerifiedEmail: state.profileReducer.hasVerifiedEmail,
    hasVerifiedPhoneNumber: state.profileReducer.hasVerifiedPhoneNumber,
  };
};

var mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      goTo,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuickActionsSection);
