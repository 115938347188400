import * as ATypes from '../../shared/actionTypes';

export const discussionsReducer = (state, action) => {
  if (!state) {
    state = {
      discussions: [],
      loading: true,
      error: null,
    };
  }

  switch (action.type) {
    case ATypes.GET_DISCUSSIONS_REQ:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ATypes.GET_DISCUSSIONS_RES:
      return {
        ...state,
        discussions: action.payload,
        loading: false,
        error: null,
      };
    case ATypes.GET_DISCUSSIONS_ERR:
      return {
        ...state,
        discussions: [],
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
