import * as ATypes from '../../shared/actionTypes';

export const calendarReducer = (state, action) => {
  if (!state) {
    state = {
      calendarData: [],
      isLoading: [],
      error: null,
      calendarEvent: undefined,
    };
  }

  switch (action.type) {
    case ATypes.GET_CALENDAR_RES:
      return {
        ...state,
        calendarData: action.payload,
      };
    case ATypes.GET_CALENDAR_EVENT_REQ: {
      return {
        ...state,
        calendarEvent: undefined,
        isLoading: true,
        error: null,
      };
    }
    case ATypes.GET_CALENDAR_EVENT_RES: {
      return {
        ...state,
        calendarEvent: action.payload,
        isLoading: false,
      };
    }
    case ATypes.GET_CALENDAR_EVENT_FAIL: {
      return {
        ...state,
        calendarEvent: undefined,
        isLoading: false,
        error: action.payload,
      };
    }
    default:
      return state;
  }
};
