import React, { memo } from 'react';
import { ChevronRight } from 'lucide-react';
import { Typography } from '@/components/novoville';

const DiscussionListItem = ({ discussion, setActiveDiscussion }) => {
  const { name, title } = discussion;

  const handleDiscussionClick = () => {
    setActiveDiscussion(name);
  };

  return (
    <div className="flex flex-1 py-6">
      <div
        className="flex flex-1 items-center justify-between 
                  py-3 px-4 rounded-xl 
                  bg-transparent hover:bg-gray-100 active:bg-gray-200
                  cursor-pointer"
        onClick={handleDiscussionClick}
      >
        <Typography variant="semibold_16" className="flex-1">
          {title}
        </Typography>
        <ChevronRight size={24} />
      </div>
    </div>
  );
};

export default memo(DiscussionListItem);
